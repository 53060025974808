const wordpressBlockSchemas = [
    {
      "acf_fc_layout": "sub_navigation_breadcrumbs",
      "default_data": false,
      "call_to_action": "",
      "layout": "sub_navigation_breadcrumbs",
      "section_margin": "no_margin",
      "data_source": "default"
    },
    {
      "acf_fc_layout": "double_feature",
      "section_title": "",
      "section_sub_title": "",
      "default_data": {
        "": null,
        "column_1_text_alignment": "center",
        "column_1_text_color": "white",
        "title_1": "",
        "sub_title_1": "",
        "link_1_style": "default",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_1": false,
        "column_2_text_alignment": "center",
        "column_2_text_color": "white",
        "title_2": "",
        "sub_title_2": "",
        "link_2_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_2": {
          "ID": 7,
          "id": 7,
          "title": "img-square-placeholder",
          "filename": "img-square-placeholder.png",
          "filesize": 94228,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=7",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-square-placeholder",
          "status": "inherit",
          "uploaded_to": 18,
          "date": "2021-04-21 21:52:25",
          "modified": "2021-04-21 22:21:29",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 900,
          "height": 900,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-300x300.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-768x768.png",
            "medium_large-width": 768,
            "medium_large-height": 768,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "large-width": 900,
            "large-height": 900,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "1536x1536-width": 900,
            "1536x1536-height": 900,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "2048x2048-width": 900,
            "2048x2048-height": 900,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-small-width": 432,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-medium-width": 576,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-large-width": 864,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-large-width": 400,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        }
      },
      "layout": "feature_v9",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "products_overview",
      "section_title": "",
      "section_sub_title": "The Products overview is for showing a group of products that can filtered by common attributes. ",
      "default_data": [
        {
          "products_overview_group": {
            "products": [
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },
                "tags": [
                  {
                    "tag": "group 1"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 2",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },
                "tags": [
                  {
                    "tag": "group 1"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 3",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 1"
                  }
                ]
              }
            ]
          }
        },
        {
          "products_overview_group": {
            "products": [
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },
                "tags": [
                  {
                    "tag": "group 2"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 1",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 2"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 2",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 2"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 3",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 3"
                  }
                ]
              }
            ]
          }
        },
        {
          "products_overview_group": {
            "products": [
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 3"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },

                "tags": [
                  {
                    "tag": "group 3"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 2",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },
                "tags": [
                  {
                    "tag": "group 3"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "Product 3",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                    "ID": 5,
                    "id": 5,
                    "title": "img-hero-placeholder",
                    "filename": "img-hero-placeholder.png",
                    "filesize": 161445,
                    "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                    "link": "https://base.bloomcudev.com/?attachment_id=5",
                    "alt": "",
                    "author": "1",
                    "description": "",
                    "caption": "",
                    "name": "img-hero-placeholder",
                    "status": "inherit",
                    "uploaded_to": 11,
                    "date": "2021-04-21 21:52:22",
                    "modified": "2022-01-21 19:04:37",
                    "menu_order": 0,
                    "mime_type": "image/png",
                    "type": "image",
                    "subtype": "png",
                    "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                    "width": 1516,
                    "height": 947,
                    "sizes": {
                        "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
                        "medium-width": 300,
                        "medium-height": 187,
                        "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
                        "medium_large-width": 768,
                        "medium_large-height": 480,
                        "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
                        "large-width": 1024,
                        "large-height": 640,
                        "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "1536x1536-width": 1516,
                        "1536x1536-height": 947,
                        "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "2048x2048-width": 1516,
                        "2048x2048-height": 947,
                        "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-small-width": 692,
                        "full-page-image-small-height": 432,
                        "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-medium-width": 922,
                        "full-page-image-medium-height": 576,
                        "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "full-page-image-large-width": 1383,
                        "full-page-image-large-height": 864,
                        "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-small-width": 320,
                        "sub-page-small-height": 200,
                        "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-medium-width": 427,
                        "sub-page-medium-height": 267,
                        "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "sub-page-large-width": 640,
                        "sub-page-large-height": 400,
                        "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-small-width": 64,
                        "icon-small-height": 40,
                        "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
                        "icon-md-width": 128,
                        "icon-md-height": 80
                    }
                    },
                "tags": [
                  {
                    "tag": "group 3"
                  }
                ]
              }
            ]
          }
        }
      ],
      "layout": "default",
      "card_layout": "default-link",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },

      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "login_left",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "angle",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "angle_left",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "angle_one_col",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "login",
      "pre_title": "Login",
      "title": "",
      "content": "",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "link_1": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "angle_one_col_right",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "sticky_hero",
      "section_background": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "section_title": "",
      "section_content": "",
      "section_cta": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "section_cta_2": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "default",
      "section_margin": "default",
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero_search",
      "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
      "pre_title": "",
      "title": "",
      "sub_title": "",
      "link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "cards_vertical",
      "section_title": "",
      "section_sub_title": "",
      "section_link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "default_data": [
        {
          "card_title": "",
          "card_body": ""
        },
        {
          "card_title": "",
          "card_body": ""
        },
        {
          "card_title": "",
          "card_body": ""
        },
        {
          "card_title": "",
          "card_body": ""
        }
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "card_three_column_shadow",
      "default_data": [
        {
          "title": "",
          "link_type": "basic_link",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false,
          "link_style": "button",
          "content": ""
        },
        {
          "title": "",
          "link_type": "basic_link",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false,
          "link_style": "button",
          "content": ""
        },
        {
          "title": "",
          "link_type": "basic_link",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false,
          "link_style": "button",
          "content": ""
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "feature_benefits",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "primary",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "secondary",
        "media_type": "image",
        "image": false,
        "video_embed": ""
      },
      "benefits": [
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        }
      ],
      "layout": "feature",
      "section_margin": "top_bottom",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": false,
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature_benefits",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "primary",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "secondary",
        "media_type": "image",
        "image": false,
        "video_embed": ""
      },
      "benefits": [
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        },
        {
          "Icon": {
            "ID": 3461,
            "id": 3461,
            "title": "Mortgage Discount - sketch",
            "filename": "Mortgage-Discount-sketch.svg",
            "filesize": 1935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/mortgage-discount-sketch/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "mortgage-discount-sketch",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:13",
            "modified": "2024-07-31 19:56:07",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 29,
            "height": 29,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-small-width": 29,
              "icon-small-height": 29,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Mortgage-Discount-sketch.svg",
              "icon-md-width": 29,
              "icon-md-height": 29
            }
          },
          "benefit_text": ""
        }
      ],
      "layout": "feature_invert",
      "section_margin": "top_bottom",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": false,
      "section_classes": ""
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": "",
        "link_2_style": "default"
      },
      "layout": "center",
      "section_background": "default",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "router",
      "title_display_style": "h3",
      "section_title": "",
      "section_sub_title": "",
      "default_data": [
        {
          "image": {
            "ID": 3459,
            "id": 3459,
            "title": "Education",
            "filename": "Education.svg",
            "filesize": 935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/education-2/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "education-2",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:12",
            "modified": "2024-10-31 18:46:40",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 50,
            "height": 50,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-small-width": 50,
              "icon-small-height": 50,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-md-width": 50,
              "icon-md-height": 50
            }
          },
          "link_type": "basic_link",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "image": {
            "ID": 3459,
            "id": 3459,
            "title": "Education",
            "filename": "Education.svg",
            "filesize": 935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/education-2/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "education-2",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:12",
            "modified": "2024-10-31 18:46:40",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 50,
            "height": 50,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-small-width": 50,
              "icon-small-height": 50,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-md-width": 50,
              "icon-md-height": 50
            }
          },
          "link_type": "basic_link",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "image": {
            "ID": 3459,
            "id": 3459,
            "title": "Education",
            "filename": "Education.svg",
            "filesize": 935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/education-2/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "education-2",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:12",
            "modified": "2024-10-31 18:46:40",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 50,
            "height": 50,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-small-width": 50,
              "icon-small-height": 50,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-md-width": 50,
              "icon-md-height": 50
            }
          },
          "link_type": "basic_link",
          "link_1": {
            "title": "Link 3",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "image": {
            "ID": 3459,
            "id": 3459,
            "title": "Education",
            "filename": "Education.svg",
            "filesize": 935,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
            "link": "https://base.bloomcudev.com/block-library/router-block/education-2/",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "education-2",
            "status": "inherit",
            "uploaded_to": 3580,
            "date": "2022-12-02 18:57:12",
            "modified": "2024-10-31 18:46:40",
            "menu_order": 0,
            "mime_type": "image/svg+xml",
            "type": "image",
            "subtype": "svg+xml",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 50,
            "height": 50,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "medium_large-width": 768,
              "medium_large-height": 1,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "large-width": 1024,
              "large-height": 1024,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1536,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "2048x2048-width": 2048,
              "2048x2048-height": 2048,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-small-width": 50,
              "icon-small-height": 50,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/12/Education.svg",
              "icon-md-width": 50,
              "icon-md-height": 50
            }
          },
          "link_type": "basic_link",
          "link_1": {
            "title": "Link 4",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "vertical_offset": "xl",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_video",
      "title_display_style": "h4",
      "section_title": "",
      "section_sub_title": "",
      "video_url": "https://www.youtube.com/embed/K4TOrB7at0Y",
      "link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_1_style_link_style": "default",
      "link_2": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_2_style_link_style": "tertiary",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "feature_video",
      "title_display_style": "h4",
      "section_title": "",
      "section_sub_title": "",
      "video_url": "https://player.vimeo.com/video/30740120",
      "link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_1_style_link_style": "default",
      "link_2": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_2_style_link_style": "default",
      "layout": "reverse",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "hero_immersive",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": "",
        "link_2_style": "default"
      },
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "products_overview",
      "section_title": "",
      "section_sub_title": "",
      "default_data": [
        {
          "products_overview_group": {
            "products": [
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                  "ID": 7,
                  "id": 7,
                  "title": "img-square-placeholder",
                  "filename": "img-square-placeholder.png",
                  "filesize": 94228,
                  "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                  "link": "https://base.bloomcudev.com/?attachment_id=7",
                  "alt": "",
                  "author": "1",
                  "description": "",
                  "caption": "",
                  "name": "img-square-placeholder",
                  "status": "inherit",
                  "uploaded_to": 18,
                  "date": "2021-04-21 21:52:25",
                  "modified": "2021-04-21 22:21:29",
                  "menu_order": 0,
                  "mime_type": "image/png",
                  "type": "image",
                  "subtype": "png",
                  "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                  "width": 900,
                  "height": 900,
                  "sizes": {
                    "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-150x150.png",
                    "thumbnail-width": 150,
                    "thumbnail-height": 150,
                    "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-300x300.png",
                    "medium-width": 300,
                    "medium-height": 300,
                    "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-768x768.png",
                    "medium_large-width": 768,
                    "medium_large-height": 768,
                    "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "large-width": 900,
                    "large-height": 900,
                    "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "1536x1536-width": 900,
                    "1536x1536-height": 900,
                    "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "2048x2048-width": 900,
                    "2048x2048-height": 900,
                    "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "full-page-image-small-width": 432,
                    "full-page-image-small-height": 432,
                    "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "full-page-image-medium-width": 576,
                    "full-page-image-medium-height": 576,
                    "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "full-page-image-large-width": 864,
                    "full-page-image-large-height": 864,
                    "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "sub-page-small-width": 200,
                    "sub-page-small-height": 200,
                    "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "sub-page-medium-width": 267,
                    "sub-page-medium-height": 267,
                    "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "sub-page-large-width": 400,
                    "sub-page-large-height": 400,
                    "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "icon-small-width": 64,
                    "icon-small-height": 64,
                    "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
                    "icon-md-width": 128,
                    "icon-md-height": 128
                  }
                },
                "tags": [
                  {
                    "tag": "Tag 1"
                  },
                  {
                    "tag": "Tag 2"
                  }
                ]
              },
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": {
                  "title": "",
                  "url": "#",
                  "target": ""
                },
                "image": {
                  "ID": 8,
                  "id": 8,
                  "title": "img-thumbnail-placeholder",
                  "filename": "img-thumbnail-placeholder.png",
                  "filesize": 19936,
                  "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                  "link": "https://base.bloomcudev.com/?attachment_id=8",
                  "alt": "",
                  "author": "1",
                  "description": "",
                  "caption": "",
                  "name": "img-thumbnail-placeholder",
                  "status": "inherit",
                  "uploaded_to": 22,
                  "date": "2021-04-21 21:52:26",
                  "modified": "2021-04-21 22:23:21",
                  "menu_order": 0,
                  "mime_type": "image/png",
                  "type": "image",
                  "subtype": "png",
                  "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
                  "width": 300,
                  "height": 300,
                  "sizes": {
                    "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
                    "thumbnail-width": 150,
                    "thumbnail-height": 150,
                    "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "medium-width": 300,
                    "medium-height": 300,
                    "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "medium_large-width": 300,
                    "medium_large-height": 300,
                    "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "large-width": 300,
                    "large-height": 300,
                    "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "1536x1536-width": 300,
                    "1536x1536-height": 300,
                    "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "2048x2048-width": 300,
                    "2048x2048-height": 300,
                    "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "full-page-image-small-width": 300,
                    "full-page-image-small-height": 300,
                    "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "full-page-image-medium-width": 300,
                    "full-page-image-medium-height": 300,
                    "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "full-page-image-large-width": 300,
                    "full-page-image-large-height": 300,
                    "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "sub-page-small-width": 200,
                    "sub-page-small-height": 200,
                    "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "sub-page-medium-width": 267,
                    "sub-page-medium-height": 267,
                    "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "sub-page-large-width": 300,
                    "sub-page-large-height": 300,
                    "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "icon-small-width": 64,
                    "icon-small-height": 64,
                    "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
                    "icon-md-width": 128,
                    "icon-md-height": 128
                  }
                },
                "tags": [
                  {
                    "tag": "Tag 2"
                  },
                  {
                    "tag": "Tag 3"
                  }
                ]
              }
            ]
          }
        },
        {
          "products_overview_group": {
            "products": [
              {
                "pre_title": "",
                "title": "",
                "sub_title": "",
                "link": "",
                "image": false,
                "tags": [
                  {
                    "tag": "Tag 4"
                  }
                ]
              }
            ]
          }
        }
      ],
      "layout": "default",
      "card_layout": "default-link",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "text",
      "title": "",
      "title_display_style": "default",
      "content": "",
      "link": {
        "title": "",
        "url": "#medium",
        "target": ""
      },
      "button_style": "default",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "text",
      "title": "",
      "title_display_style": "default",
      "content": "",
      "link": "",
      "button_style": "default",
      "layout": "default",
      "section_background": "default",
      "section_margin": "top_only",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "looping_tabs",
      "tabs": [
        {
          "tab_title": "",
          "content_title": "",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "link_2": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "media_type": "image",
          "image": {
            "ID": 5,
            "id": 5,
            "title": "img-hero-placeholder",
            "filename": "img-hero-placeholder.png",
            "filesize": 161445,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=5",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-hero-placeholder",
            "status": "inherit",
            "uploaded_to": 11,
            "date": "2021-04-21 21:52:22",
            "modified": "2022-01-21 19:04:37",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 1516,
            "height": 947,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
              "medium-width": 300,
              "medium-height": 187,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
              "medium_large-width": 768,
              "medium_large-height": 480,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
              "large-width": 1024,
              "large-height": 640,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "1536x1536-width": 1516,
              "1536x1536-height": 947,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "2048x2048-width": 1516,
              "2048x2048-height": 947,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-small-width": 692,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-medium-width": 922,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-large-width": 1383,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-small-width": 320,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-medium-width": 427,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-large-width": 640,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 40,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 80
            }
          },
          "video_file": false,
          "video_url": ""
        },
        {
          "tab_title": "",
          "content_title": "",
          "content": "",
          "link": "",
          "link_2": "",
          "media_type": "image",
          "image": {
            "ID": 5,
            "id": 5,
            "title": "img-hero-placeholder",
            "filename": "img-hero-placeholder.png",
            "filesize": 161445,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=5",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-hero-placeholder",
            "status": "inherit",
            "uploaded_to": 11,
            "date": "2021-04-21 21:52:22",
            "modified": "2022-01-21 19:04:37",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 1516,
            "height": 947,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
              "medium-width": 300,
              "medium-height": 187,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
              "medium_large-width": 768,
              "medium_large-height": 480,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
              "large-width": 1024,
              "large-height": 640,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "1536x1536-width": 1516,
              "1536x1536-height": 947,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "2048x2048-width": 1516,
              "2048x2048-height": 947,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-small-width": 692,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-medium-width": 922,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-large-width": 1383,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-small-width": 320,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-medium-width": 427,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-large-width": 640,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 40,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 80
            }
          },
          "video_file": false,
          "video_url": ""
        },
        {
          "tab_title": "",
          "content_title": "",
          "content": "",
          "link": "",
          "link_2": "",
          "media_type": "image",
          "image": {
            "ID": 5,
            "id": 5,
            "title": "img-hero-placeholder",
            "filename": "img-hero-placeholder.png",
            "filesize": 161445,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=5",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-hero-placeholder",
            "status": "inherit",
            "uploaded_to": 11,
            "date": "2021-04-21 21:52:22",
            "modified": "2022-01-21 19:04:37",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 1516,
            "height": 947,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
              "medium-width": 300,
              "medium-height": 187,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
              "medium_large-width": 768,
              "medium_large-height": 480,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
              "large-width": 1024,
              "large-height": 640,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "1536x1536-width": 1516,
              "1536x1536-height": 947,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "2048x2048-width": 1516,
              "2048x2048-height": 947,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-small-width": 692,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-medium-width": 922,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-large-width": 1383,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-small-width": 320,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-medium-width": 427,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-large-width": 640,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 40,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 80
            }
          },
          "video_file": false,
          "video_url": ""
        },
        {
          "tab_title": "Impedit nulla ",
          "content_title": "",
          "content": "",
          "link": "",
          "link_2": "",
          "media_type": "image",
          "image": {
            "ID": 5,
            "id": 5,
            "title": "img-hero-placeholder",
            "filename": "img-hero-placeholder.png",
            "filesize": 161445,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=5",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-hero-placeholder",
            "status": "inherit",
            "uploaded_to": 11,
            "date": "2021-04-21 21:52:22",
            "modified": "2022-01-21 19:04:37",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 1516,
            "height": 947,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
              "medium-width": 300,
              "medium-height": 187,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
              "medium_large-width": 768,
              "medium_large-height": 480,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
              "large-width": 1024,
              "large-height": 640,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "1536x1536-width": 1516,
              "1536x1536-height": 947,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "2048x2048-width": 1516,
              "2048x2048-height": 947,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-small-width": 692,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-medium-width": 922,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "full-page-image-large-width": 1383,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-small-width": 320,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-medium-width": 427,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "sub-page-large-width": 640,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 40,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 80
            }
          },
          "video_file": false,
          "video_url": ""
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "no_margin",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "locator",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        2759
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        2762
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        2761
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        498
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        2764
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "calculator",
      "section_title": "",
      "section_sub_title": "",
      "calculator_data": [
        2763
      ],
      "layout": "default",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "calculator",
      "background_image": false
    },
    {
      "acf_fc_layout": "rate_repeater",
      "section_title": "",
      "default_data": [
        {
          "rate_title": "Auto Loan",
          "rate": "1.95",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "Mortgage",
          "rate": "3.00",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "Credit Card",
          "rate": "2.40",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "",
          "rate": "3.3",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        }
      ],
      "section_cta": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_1_style_link_style": "primary",
      "section_cta_2": {
        "title": "Ipsum",
        "url": "#",
        "target": ""
      },
      "button_2_style_link_style": "secondary",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "rate_repeater",
      "section_title": "",
      "default_data": [
        {
          "rate_title": "Auto Loan",
          "rate": "1.95",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "Mortgage",
          "rate": "3.00",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "Credit Card",
          "rate": "2.40",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        },
        {
          "rate_title": "",
          "rate": "3.3",
          "rate_suffix": "%",
          "rate_term": "APR",
          "rate_sub_label": "as low as"
        }
      ],
      "section_cta": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_1_style_link_style": "default",
      "section_cta_2": "",
      "button_2_style_link_style": "default",
      "layout": "centered",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "icon_repeater",
      "section_title": "Thumbnails",
      "section_sub_title": "Thumbnail blocks are used to show a group of small graphics, statistics or product features.",
      "default_data": [
        {
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "image_caption": "Thumbnail Title"
        },
        {
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "image_caption": "Thumbnail Title"
        },
        {
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "image_caption": "Thumbnail Title"
        },
        {
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "image_caption": "Thumbnail Title"
        }
      ],
      "testimonial": "",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "table_repeater",
      "section_title": "Tables (Multiple)",
      "section_sub_title": "Table blocks are used to display a single table or to repeat multiple tables as a group.",
      "tables_data": [
        {
          "ID": 1791,
          "post_author": "1",
          "post_date": "2021-07-19 04:07:00",
          "post_date_gmt": "2021-07-19 04:07:00",
          "post_content": "",
          "post_title": "Example Comparison Table",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "closed",
          "ping_status": "closed",
          "post_password": "",
          "post_name": "example-comparison-table",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-07-19 04:07:00",
          "post_modified_gmt": "2021-07-19 04:07:00",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?post_type=tables&#038;p=1791",
          "menu_order": 0,
          "post_type": "tables",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        },
        {
          "ID": 1311,
          "post_author": "1",
          "post_date": "2021-07-11 07:21:29",
          "post_date_gmt": "2021-07-11 07:21:29",
          "post_content": "",
          "post_title": "Example Table",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "closed",
          "ping_status": "closed",
          "post_password": "",
          "post_name": "example-table",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2022-06-13 19:48:37",
          "post_modified_gmt": "2022-06-13 19:48:37",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://redwood.bloomcudev.com/?post_type=tables&#038;p=1311",
          "menu_order": 0,
          "post_type": "tables",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "tables",
      "background_image": false
    },
    {
      "acf_fc_layout": "table",
      "section_title": "Table",
      "section_sub_title": "Table blocks are used to display a single table or to repeat multiple tables as a group.",
      "show_table_title": true,
      "table_data": {
        "ID": 1311,
        "post_author": "1",
        "post_date": "2021-07-11 07:21:29",
        "post_date_gmt": "2021-07-11 07:21:29",
        "post_content": "",
        "post_title": "Example Table",
        "post_excerpt": "",
        "post_status": "publish",
        "comment_status": "closed",
        "ping_status": "closed",
        "post_password": "",
        "post_name": "example-table",
        "to_ping": "",
        "pinged": "",
        "post_modified": "2022-06-13 19:48:37",
        "post_modified_gmt": "2022-06-13 19:48:37",
        "post_content_filtered": "",
        "post_parent": 0,
        "guid": "https://redwood.bloomcudev.com/?post_type=tables&#038;p=1311",
        "menu_order": 0,
        "post_type": "tables",
        "post_mime_type": "",
        "comment_count": "0",
        "filter": "raw"
      },
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "table",
      "background_image": false
    },
    {
      "acf_fc_layout": "text",
      "title": "Text Block",
      "title_display_style": "default",
      "content": "<p>The Text Block is good for single column, long-form content. Vitae qui adipisci repellendus reiciendis. Consequatur in dolores eius expedita. Eligendi qui qui eum et autem. Vitae qui adipisci repellendus reiciendis. Consequatur in dolores eius expedita.</p>\n",
      "link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "button_style": "color-inherit",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "feature_devices",
      "mobile_and_desktop": {
        "": "",
        "title": "",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "device_image_1": false,
        "device_image_2": false
      },
      "mobile": {
        "": "",
        "title": "",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "device_image_1": false
      },
      "layout": "mobile",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "feature_devices",
      "mobile_and_desktop": {
        "": "",
        "title": "",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "device_image_1": false,
        "device_image_2": false
      },
      "mobile": {
        "": "",
        "title": "",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "device_image_1": false
      },
      "layout": "mobile_and_desktop",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "<span class=\"color-white text-center\">Section divider (default)</span>",
      "section_content": "<span class=\"color-white text-center\">Nisi est dolore ut repudiandae blanditiis. Possimus eius quod corrupti temporibus. Ipsa omnis vero voluptas facere blanditiis maiores.</span>",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "",
      "section_content": "",
      "layout": "curve_2",
      "section_background": "bg-2",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "Section Divider (Curve 3)",
      "section_content": "Alias sed dolorum Incidunt reprehenderit ipsam voluptas aut molestiae est esse. Recusandae facilis atque enim quos at est alias.",
      "layout": "curve_3",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "Section Divider (Paint)",
      "section_content": "",
      "layout": "paint",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "",
      "section_content": "",
      "layout": "paint",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "section_divider",
      "section_title": "Section Divider (Paint 2)",
      "section_content": "Natus odio praesentium atque minus eius. Cupiditate officia quasi fuga est dolores ut dolor est. Omnis blanditiis et voluptatem dolor.",
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": {
        "ID": 5,
        "id": 5,
        "title": "img-hero-placeholder",
        "filename": "img-hero-placeholder.png",
        "filesize": 161445,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
        "link": "https://base.bloomcudev.com/?attachment_id=5",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "img-hero-placeholder",
        "status": "inherit",
        "uploaded_to": 11,
        "date": "2021-04-21 21:52:22",
        "modified": "2022-01-21 19:04:37",
        "menu_order": 0,
        "mime_type": "image/png",
        "type": "image",
        "subtype": "png",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1516,
        "height": 947,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
          "thumbnail-width": 150,
          "thumbnail-height": 150,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
          "medium-width": 300,
          "medium-height": 187,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
          "medium_large-width": 768,
          "medium_large-height": 480,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
          "large-width": 1024,
          "large-height": 640,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "1536x1536-width": 1516,
          "1536x1536-height": 947,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "2048x2048-width": 1516,
          "2048x2048-height": 947,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-small-width": 692,
          "full-page-image-small-height": 432,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-medium-width": 922,
          "full-page-image-medium-height": 576,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "full-page-image-large-width": 1383,
          "full-page-image-large-height": 864,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-small-width": 320,
          "sub-page-small-height": 200,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-medium-width": 427,
          "sub-page-medium-height": 267,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "sub-page-large-width": 640,
          "sub-page-large-height": 400,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-small-width": 64,
          "icon-small-height": 40,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "icon-md-width": 128,
          "icon-md-height": 80
        }
      }
    },
    {
      "acf_fc_layout": "video_hero",
      "hero_fields_video_background_hero_modal": {
        "title": "Video Hero",
        "sub_title": "",
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "video_url": "https://www.youtube.com/embed/LbeuiDCvb4M",
        "video_clip": "",
        "background_video_placeholder_image": false
      },
      "hero_fields_video_background": {
        "": "",
        "title": "",
        "sub_title": "",
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "video_url": "https://www.youtube.com/embed/LbeuiDCvb4M",
        "background_video_placeholder_image": false
      },
      "layout": "video_background",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "video_hero",
      "hero_fields_video_background_hero_modal": {
        "title": "Video Hero",
        "sub_title": "",
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "video_url": "https://www.youtube.com/embed/LbeuiDCvb4M",
        "video_clip": "https://www.youtube.com/embed/LbeuiDCvb4M",
        "background_video_placeholder_image": false
      },
      "hero_fields_video_background": {
        "": "",
        "title": "",
        "sub_title": "",
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "video_url": "https://www.youtube.com/embed/LbeuiDCvb4M",
        "background_video_placeholder_image": false
      },
      "layout": "video_background_hero_modal",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "block_clone",
      "block_clone_data": {
        "ID": 77,
        "post_author": "1",
        "post_date": "2021-04-21 23:02:30",
        "post_date_gmt": "2021-04-21 23:02:30",
        "post_content": "",
        "post_title": "Example Block Clone",
        "post_excerpt": "",
        "post_status": "publish",
        "comment_status": "closed",
        "ping_status": "closed",
        "post_password": "",
        "post_name": "block-library-sub-nav",
        "to_ping": "",
        "pinged": "",
        "post_modified": "2021-04-21 23:05:49",
        "post_modified_gmt": "2021-04-21 23:05:49",
        "post_content_filtered": "",
        "post_parent": 0,
        "guid": "https://base.bloomcudev.com/?post_type=base-block-clone&#038;p=77",
        "menu_order": 0,
        "post_type": "base-block-clone",
        "post_mime_type": "",
        "comment_count": "0",
        "filter": "raw"
      },
      "layout": "default",
      "data_source": "block_clone"
    },
    {
      "acf_fc_layout": "sub_navigation",
      "default_data": [
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        }
      ],
      "layout": "sub_navigation_collapse",
      "section_margin": "default",
      "data_source": "default"
    },
    {
      "acf_fc_layout": "sub_navigation",
      "default_data": [
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        },
        {
          "link": {
            "title": "Longer Link Title",
            "url": "#",
            "target": ""
          }
        }
      ],
      "layout": "sub_navigation",
      "section_margin": "default",
      "data_source": "default"
    },
    {
      "acf_fc_layout": "process_repeater",
      "section_title": "Section Title: Process Repeater - Default",
      "section_sub_title": "",
      "section_link": {
        "title": "",
        "url": "#",
        "target": ""
      },
      "default_data": [
        {
          "title": "Step Title",
          "content": ""
        },
        {
          "title": "Step Title",
          "content": ""
        },
        {
          "title": "Step Title",
          "content": ""
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "card_repeater",
      "section_title": "Cards - Default Link (Img Size)",
      "section_sub_title": "Section subtitle",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link"
        }
      ],
      "posts_data": [
        {
          "ID": 1,
          "post_author": "1",
          "post_date": "2021-04-21 21:50:40",
          "post_date_gmt": "2021-04-21 21:50:40",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-one",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:39",
          "post_modified_gmt": "2021-11-12 01:52:39",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=1",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "1",
          "filter": "raw"
        },
        {
          "ID": 64,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:45",
          "post_date_gmt": "2021-04-21 22:52:45",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-three",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:58",
          "post_modified_gmt": "2021-11-12 01:52:58",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=64",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        },
        {
          "ID": 60,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:32",
          "post_date_gmt": "2021-04-21 22:52:32",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-two",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:48",
          "post_modified_gmt": "2021-11-12 01:52:48",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=60",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        }
      ],
      "layout": "v9",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "section_background_image": false,
      "background_image": false
    },
    {
      "acf_fc_layout": "card_repeater",
      "section_title": "Cards - Default Link (1:1)",
      "section_sub_title": "Section subtitle",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link-fixed-image"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link-fixed-image"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link-fixed-image"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-link-fixed-image"
        }
      ],
      "posts_data": [
        {
          "ID": 1,
          "post_author": "1",
          "post_date": "2021-04-21 21:50:40",
          "post_date_gmt": "2021-04-21 21:50:40",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-one",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:39",
          "post_modified_gmt": "2021-11-12 01:52:39",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=1",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "1",
          "filter": "raw"
        }
      ],
      "layout": "v9",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "section_background_image": false,
      "background_image": false
    },
    {
      "acf_fc_layout": "card_repeater",
      "section_title": "Default Button (3:2)",
      "section_sub_title": "Section subtitle",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-button"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-button"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-button"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "default-button"
        }
      ],
      "posts_data": null,
      "layout": "v9",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "section_background_image": false,
      "background_image": false
    },
    {
      "acf_fc_layout": "card_repeater",
      "section_title": "V9 (Background Image)",
      "section_sub_title": "Section subtitle",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v9"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v9"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v9"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v9"
        }
      ],
      "posts_data": [
        {
          "ID": 1,
          "post_author": "1",
          "post_date": "2021-04-21 21:50:40",
          "post_date_gmt": "2021-04-21 21:50:40",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-one",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:39",
          "post_modified_gmt": "2021-11-12 01:52:39",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=1",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "1",
          "filter": "raw"
        },
        {
          "ID": 64,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:45",
          "post_date_gmt": "2021-04-21 22:52:45",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-three",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:58",
          "post_modified_gmt": "2021-11-12 01:52:58",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=64",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        },
        {
          "ID": 60,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:32",
          "post_date_gmt": "2021-04-21 22:52:32",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-two",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:48",
          "post_modified_gmt": "2021-11-12 01:52:48",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=60",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        }
      ],
      "layout": "v9",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "section_background_image": false,
      "background_image": false
    },
    {
      "acf_fc_layout": "card_repeater",
      "section_title": "V3 (3:2)",
      "section_sub_title": "Section subtitle",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v3"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v3"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v3"
        },
        {
          "pre_title": "",
          "title": "",
          "sub_title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "card_layout": "v3"
        }
      ],
      "posts_data": [
        {
          "ID": 1,
          "post_author": "1",
          "post_date": "2021-04-21 21:50:40",
          "post_date_gmt": "2021-04-21 21:50:40",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-one",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:39",
          "post_modified_gmt": "2021-11-12 01:52:39",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=1",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "1",
          "filter": "raw"
        },
        {
          "ID": 64,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:45",
          "post_date_gmt": "2021-04-21 22:52:45",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-three",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:58",
          "post_modified_gmt": "2021-11-12 01:52:58",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=64",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        },
        {
          "ID": 60,
          "post_author": "1",
          "post_date": "2021-04-21 22:52:32",
          "post_date_gmt": "2021-04-21 22:52:32",
          "post_content": "",
          "post_title": "",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "open",
          "ping_status": "open",
          "post_password": "",
          "post_name": "example-post-two",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-11-12 01:52:48",
          "post_modified_gmt": "2021-11-12 01:52:48",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?p=60",
          "menu_order": 0,
          "post_type": "post",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        }
      ],
      "layout": "v9",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "section_background_image": false,
      "background_image": false
    },
    {
      "acf_fc_layout": "columns_repeater",
      "section_title": "Text Columns",
      "title_display_style": "default",
      "section_sub_title": "Text Columns are used to display basic text content in single or multi-column layouts.",
      "default_data": [
        {
          "title": "Column Title",
          "title_display_style": "default",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "button_style": "default"
        },
        {
          "title": "Column Title",
          "title_display_style": "default",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "button_style": "primary"
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "columns_repeater",
      "section_title": "",
      "title_display_style": "default",
      "section_sub_title": "",
      "default_data": [
        {
          "title": "Column Title",
          "title_display_style": "default",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "button_style": "primary"
        },
        {
          "title": "Column Title",
          "title_display_style": "default",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "button_style": "primary"
        },
        {
          "title": "Column Title",
          "title_display_style": "default",
          "content": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "button_style": "default"
        }
      ],
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "gallery_repeater",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3491,
            "id": 3491,
            "title": "Bay Of Plenty sunset",
            "filename": "bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 472113,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/auto-loans/bay-of-plenty-sunset/",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty beach New Zealand",
            "name": "bay-of-plenty-sunset",
            "status": "inherit",
            "uploaded_to": 3009,
            "date": "2023-02-16 17:56:32",
            "modified": "2023-02-16 17:56:32",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3485,
            "id": 3485,
            "title": "Bay Of Plenty view from Mount Maunganui",
            "filename": "bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 1004848,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3485",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty view from Mount Maunganui New Zealand",
            "name": "bay-of-plenty-view-from-mount-maunganui",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:43:12",
            "modified": "2023-02-16 17:43:12",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3481,
            "id": 3481,
            "title": "Coniferous trees in forest or woodlands at fall",
            "filename": "coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-scaled.jpg",
            "filesize": 943820,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3481",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "Coniferous trees in forest or woodlands at fall. Fall colors,season change and weather concept.",
            "name": "coniferous-trees-in-forest-or-woodlands-at-fall",
            "status": "inherit",
            "uploaded_to": 3013,
            "date": "2023-02-16 17:29:58",
            "modified": "2023-02-16 17:29:58",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1709,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-768x513.jpg",
              "medium_large-width": 768,
              "medium_large-height": 513,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1536x1025.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1025,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-2048x1367.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1367,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/coniferous-trees-in-forest-or-woodlands-at-fall-2022-02-02-03-48-38-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3482,
            "id": 3482,
            "title": "Outdoor Lifestyle,USA,Two children standing outdoors in a garden laughing.",
            "filename": "two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-scaled.jpg",
            "filesize": 593030,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3482",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "Two children standing outdoors in a garden laughing.",
            "name": "outdoor-lifestyleusatwo-children-standing-outdoors-in-a-garden-laughing",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:34:06",
            "modified": "2023-02-16 17:34:06",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1707,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/two-children-standing-outdoors-in-a-garden-laughin-2022-03-04-02-30-29-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3491,
            "id": 3491,
            "title": "Bay Of Plenty sunset",
            "filename": "bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 472113,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/auto-loans/bay-of-plenty-sunset/",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty beach New Zealand",
            "name": "bay-of-plenty-sunset",
            "status": "inherit",
            "uploaded_to": 3009,
            "date": "2023-02-16 17:56:32",
            "modified": "2023-02-16 17:56:32",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3485,
            "id": 3485,
            "title": "Bay Of Plenty view from Mount Maunganui",
            "filename": "bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 1004848,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3485",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty view from Mount Maunganui New Zealand",
            "name": "bay-of-plenty-view-from-mount-maunganui",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:43:12",
            "modified": "2023-02-16 17:43:12",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        }
      ],
      "layout": "gallery",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "gallery_repeater",
      "default_data": [
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3491,
            "id": 3491,
            "title": "Bay Of Plenty sunset",
            "filename": "bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 472113,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/auto-loans/bay-of-plenty-sunset/",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty beach New Zealand",
            "name": "bay-of-plenty-sunset",
            "status": "inherit",
            "uploaded_to": 3009,
            "date": "2023-02-16 17:56:32",
            "modified": "2023-02-16 17:56:32",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2023/02/bay-of-plenty-sunset-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3493,
            "id": 3493,
            "title": "Mid adult woman organising camping equipment, New Zealand",
            "filename": "mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
            "filesize": 1201789,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/auto-loans/mid-adult-woman-organising-camping-equipment-new-zealand/",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "Mid adult woman organising camping equipment, New Zealand",
            "name": "mid-adult-woman-organising-camping-equipment-new-zealand",
            "status": "inherit",
            "uploaded_to": 3009,
            "date": "2023-02-16 18:02:25",
            "modified": "2023-02-16 18:02:25",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1704,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-768x511.jpg",
              "medium_large-width": 768,
              "medium_large-height": 511,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1024x681.jpg",
              "large-width": 1024,
              "large-height": 681,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1536x1022.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1022,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-2048x1363.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1363,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3487,
            "id": 3487,
            "title": "OLYMPUS DIGITAL CAMERA",
            "filename": "ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
            "filesize": 762162,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3487",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "OLYMPUS DIGITAL CAMERA",
            "name": "olympus-digital-camera",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:49:23",
            "modified": "2023-02-16 17:49:23",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1920,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-300x225.jpg",
              "medium-width": 300,
              "medium-height": 225,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-768x576.jpg",
              "medium_large-width": 768,
              "medium_large-height": 576,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1024x768.jpg",
              "large-width": 1024,
              "large-height": 768,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1536x1152.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1152,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-2048x1536.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1536,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 48,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 96
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3485,
            "id": 3485,
            "title": "Bay Of Plenty view from Mount Maunganui",
            "filename": "bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "filesize": 1004848,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3485",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "An image of Bay Of Plenty view from Mount Maunganui New Zealand",
            "name": "bay-of-plenty-view-from-mount-maunganui",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:43:12",
            "modified": "2023-02-16 17:43:12",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1706,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-768x512.jpg",
              "medium_large-width": 768,
              "medium_large-height": 512,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1024x683.jpg",
              "large-width": 1024,
              "large-height": 683,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1536x1024.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1024,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-2048x1365.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1365,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/bay-of-plenty-view-from-mount-maunganui-2021-08-26-15-27-17-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3493,
            "id": 3493,
            "title": "Mid adult woman organising camping equipment, New Zealand",
            "filename": "mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
            "filesize": 1201789,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/auto-loans/mid-adult-woman-organising-camping-equipment-new-zealand/",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "Mid adult woman organising camping equipment, New Zealand",
            "name": "mid-adult-woman-organising-camping-equipment-new-zealand",
            "status": "inherit",
            "uploaded_to": 3009,
            "date": "2023-02-16 18:02:25",
            "modified": "2023-02-16 18:02:25",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1704,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-300x200.jpg",
              "medium-width": 300,
              "medium-height": 200,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-768x511.jpg",
              "medium_large-width": 768,
              "medium_large-height": 511,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1024x681.jpg",
              "large-width": 1024,
              "large-height": 681,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1536x1022.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1022,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-2048x1363.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1363,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 43,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2023/02/mid-adult-woman-organising-camping-equipment-new-2022-03-04-01-43-54-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 85
            }
          }
        },
        {
          "pre_title": "",
          "title": "",
          "link": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "image": {
            "ID": 3487,
            "id": 3487,
            "title": "OLYMPUS DIGITAL CAMERA",
            "filename": "ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
            "filesize": 762162,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
            "link": "https://base.bloomcudev.com/?attachment_id=3487",
            "alt": "",
            "author": "2",
            "description": "",
            "caption": "OLYMPUS DIGITAL CAMERA",
            "name": "olympus-digital-camera",
            "status": "inherit",
            "uploaded_to": 3027,
            "date": "2023-02-16 17:49:23",
            "modified": "2023-02-16 17:49:23",
            "menu_order": 0,
            "mime_type": "image/jpeg",
            "type": "image",
            "subtype": "jpeg",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 2560,
            "height": 1920,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-300x225.jpg",
              "medium-width": 300,
              "medium-height": 225,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-768x576.jpg",
              "medium_large-width": 768,
              "medium_large-height": 576,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1024x768.jpg",
              "large-width": 1024,
              "large-height": 768,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1536x1152.jpg",
              "1536x1536-width": 1536,
              "1536x1536-height": 1152,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-2048x1536.jpg",
              "2048x2048-width": 2048,
              "2048x2048-height": 1536,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-960x432.jpg",
              "full-page-image-small-width": 960,
              "full-page-image-small-height": 432,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1280x576.jpg",
              "full-page-image-medium-width": 1280,
              "full-page-image-medium-height": 576,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1920x864.jpg",
              "full-page-image-large-width": 1920,
              "full-page-image-large-height": 864,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-960x200.jpg",
              "sub-page-small-width": 960,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1280x267.jpg",
              "sub-page-medium-width": 1280,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-1920x400.jpg",
              "sub-page-large-width": 1920,
              "sub-page-large-height": 400,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
              "icon-small-width": 64,
              "icon-small-height": 48,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/06/ketchikan-alaska-harbor-with-lots-of-fishing-boats-2022-11-09-12-53-28-utc-scaled.jpg",
              "icon-md-width": 128,
              "icon-md-height": 96
            }
          }
        }
      ],
      "layout": "advanced_gallery_v2",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "accordion_repeater",
      "section_title": "Accordion Repeater - Default",
      "section_sub_title": "",
      "default_data": [
        {
          "title": "Accordion Title",
          "content": ""
        },
        {
          "title": "Accordion Title",
          "content": ""
        },
        {
          "title": "Accordion Title",
          "content": ""
        }
      ],
      "faqs_data": 2,
      "layout": "default",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "accordion_repeater",
      "section_title": "Accordion Repeater - FAQs",
      "section_sub_title": "",
      "default_data": false,
      "faqs_data": 2,
      "layout": "default",
      "section_background": "bg-1",
      "section_margin": "default",
      "data_source": "faqs",
      "background_image": false
    },
    {
      "acf_fc_layout": "testimonial_repeater",
      "section_title": "Section Title: Testimonial Repeater - Slider",
      "default_data": [
        31,
        35,
        36
      ],
      "layout": "slider",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "testimonial_repeater",
      "section_title": "Section Title: Testimonial Repeater - Cards",
      "default_data": [
        31,
        35,
        36
      ],
      "layout": "cards",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "testimonial",
      "testimonial": [
        {
          "ID": 31,
          "post_author": "1",
          "post_date": "2021-04-21 22:28:20",
          "post_date_gmt": "2021-04-21 22:28:20",
          "post_content": "",
          "post_title": "Example Testimonial One",
          "post_excerpt": "",
          "post_status": "publish",
          "comment_status": "closed",
          "ping_status": "closed",
          "post_password": "",
          "post_name": "example-testimonial-one",
          "to_ping": "",
          "pinged": "",
          "post_modified": "2021-09-13 21:17:08",
          "post_modified_gmt": "2021-09-13 21:17:08",
          "post_content_filtered": "",
          "post_parent": 0,
          "guid": "https://base.bloomcudev.com/?post_type=base-testimonial&#038;p=31",
          "menu_order": 0,
          "post_type": "base-testimonial",
          "post_mime_type": "",
          "comment_count": "0",
          "filter": "raw"
        }
      ],
      "layout": "full_width_blockquote",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "details_repeater",
      "section_title": "Details List (3 Column) - Section Title",
      "section_sub_title": "",
      "row_title": "Row Title",
      "default_data": [
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        },
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        },
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        },
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        }
      ],
      "layout": "details_list_col_3",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "details_repeater",
      "section_title": "",
      "section_sub_title": "",
      "row_title": "Row Title",
      "default_data": [
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        },
        {
          "title": "Item Title",
          "content": "",
          "link": {
            "title": "Item Link",
            "url": "#",
            "target": ""
          }
        }
      ],
      "layout": "details_list_col_3",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_repeater",
      "section_title": "Feature v7",
      "section_sub_title": "Vestibulum bibendum dignissim leo ac tempor. Sed interdum, ex eget molestie fringilla, quam turpis rhoncus nisl, ac feugiat felis tellus volutpat tortor. Sed cursus commodo arcu, ut scelerisque eros eleifend ut. ",
      "default_data": [
        {
          "title": "Feature V7 Repeater",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "primary",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V7 Repeater",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "primary",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V7 Repeater",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "primary",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V7 Repeater",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "primary",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "layout": "feature_v7",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_repeater",
      "section_title": "Feature v9",
      "section_sub_title": "Vestibulum bibendum dignissim leo ac tempor. Sed interdum, ex eget molestie fringilla, quam turpis rhoncus nisl, ac feugiat felis tellus volutpat tortor. Sed cursus commodo arcu, ut scelerisque eros eleifend ut. ",
      "default_data": [
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "layout": "feature_v9",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_repeater",
      "section_title": "Feature v9",
      "section_sub_title": "Vestibulum bibendum dignissim leo ac tempor. Sed interdum, ex eget molestie fringilla, quam turpis rhoncus nisl, ac feugiat felis tellus volutpat tortor. Sed cursus commodo arcu, ut scelerisque eros eleifend ut. ",
      "default_data": [
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "layout": "feature_v9_small_icon_centered",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_repeater",
      "section_title": "Feature v9",
      "section_sub_title": "Vestibulum bibendum dignissim leo ac tempor. Sed interdum, ex eget molestie fringilla, quam turpis rhoncus nisl, ac feugiat felis tellus volutpat tortor. Sed cursus commodo arcu, ut scelerisque eros eleifend ut. ",
      "default_data": [
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "layout": "feature_v9_small_image",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "feature_repeater",
      "section_title": "Feature v9",
      "section_sub_title": "Vestibulum bibendum dignissim leo ac tempor. Sed interdum, ex eget molestie fringilla, quam turpis rhoncus nisl, ac feugiat felis tellus volutpat tortor. Sed cursus commodo arcu, ut scelerisque eros eleifend ut. ",
      "default_data": [
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": "",
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        },
        {
          "title": "Feature V9",
          "content": "",
          "image": {
            "ID": 8,
            "id": 8,
            "title": "img-thumbnail-placeholder",
            "filename": "img-thumbnail-placeholder.png",
            "filesize": 19936,
            "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "link": "https://base.bloomcudev.com/?attachment_id=8",
            "alt": "",
            "author": "1",
            "description": "",
            "caption": "",
            "name": "img-thumbnail-placeholder",
            "status": "inherit",
            "uploaded_to": 22,
            "date": "2021-04-21 21:52:26",
            "modified": "2021-04-21 22:23:21",
            "menu_order": 0,
            "mime_type": "image/png",
            "type": "image",
            "subtype": "png",
            "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
            "width": 300,
            "height": 300,
            "sizes": {
              "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium-width": 300,
              "medium-height": 300,
              "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "medium_large-width": 300,
              "medium_large-height": 300,
              "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "large-width": 300,
              "large-height": 300,
              "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "1536x1536-width": 300,
              "1536x1536-height": 300,
              "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "2048x2048-width": 300,
              "2048x2048-height": 300,
              "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-small-width": 300,
              "full-page-image-small-height": 300,
              "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-medium-width": 300,
              "full-page-image-medium-height": 300,
              "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "full-page-image-large-width": 300,
              "full-page-image-large-height": 300,
              "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-small-width": 200,
              "sub-page-small-height": 200,
              "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-medium-width": 267,
              "sub-page-medium-height": 267,
              "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "sub-page-large-width": 300,
              "sub-page-large-height": 300,
              "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-small-width": 64,
              "icon-small-height": 64,
              "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
              "icon-md-width": 128,
              "icon-md-height": 128
            }
          },
          "link_type": "basic_link",
          "link_1_style": "default",
          "link_1": {
            "title": "",
            "url": "#",
            "target": ""
          },
          "advanced_link_title": "",
          "advanced_link_return_void": false,
          "advanced_link_attributes": false
        }
      ],
      "layout": "feature_v9_centered",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "double_feature",
      "section_title": "",
      "section_sub_title": "",
      "default_data": {
        "": null,
        "column_1_text_alignment": "center",
        "column_1_text_color": "white",
        "title_1": "Double Feature V9 Left Title",
        "sub_title_1": "Acadia Federal Credit Union is an unusual financial institution because we care deeply about our members, our communities, and each other. Our care is evident in everything we do. We even greet our members by name—because they're our friends. Heck, we even know the names of their dogs.",
        "link_1_style": "color-inherit",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_1": false,
        "column_2_text_alignment": "center",
        "column_2_text_color": "white",
        "title_2": "Double Feature V9 Right Title",
        "sub_title_2": "",
        "link_2_style": "color-inherit",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_2": {
          "ID": 7,
          "id": 7,
          "title": "img-square-placeholder",
          "filename": "img-square-placeholder.png",
          "filesize": 94228,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=7",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-square-placeholder",
          "status": "inherit",
          "uploaded_to": 18,
          "date": "2021-04-21 21:52:25",
          "modified": "2021-04-21 22:21:29",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 900,
          "height": 900,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-300x300.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-768x768.png",
            "medium_large-width": 768,
            "medium_large-height": 768,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "large-width": 900,
            "large-height": 900,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "1536x1536-width": 900,
            "1536x1536-height": 900,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "2048x2048-width": 900,
            "2048x2048-height": 900,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-small-width": 432,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-medium-width": 576,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-large-width": 864,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-large-width": 400,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        }
      },
      "layout": "feature_v9",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "double_feature",
      "section_title": "",
      "section_sub_title": "",
      "default_data": {
        "": null,
        "column_1_text_alignment": "center",
        "column_1_text_color": "contrast-higher",
        "title_1": "Double Feature V9 Left Title",
        "sub_title_1": "Acadia Federal Credit Union is an unusual financial institution because we care deeply about our members, our communities, and each other. Our care is evident in everything we do. We even greet our members by name—because they're our friends. Heck, we even know the names of their dogs.",
        "link_1_style": "primary",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_1": false,
        "column_2_text_alignment": "center",
        "column_2_text_color": "contrast-higher",
        "title_2": "Double Feature V9 Right Title",
        "sub_title_2": "",
        "link_2_style": "primary",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "image_2": {
          "ID": 7,
          "id": 7,
          "title": "img-square-placeholder",
          "filename": "img-square-placeholder.png",
          "filesize": 94228,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=7",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-square-placeholder",
          "status": "inherit",
          "uploaded_to": 18,
          "date": "2021-04-21 21:52:25",
          "modified": "2021-04-21 22:21:29",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 900,
          "height": 900,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-300x300.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-768x768.png",
            "medium_large-width": 768,
            "medium_large-height": 768,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "large-width": 900,
            "large-height": 900,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "1536x1536-width": 900,
            "1536x1536-height": 900,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "2048x2048-width": 900,
            "2048x2048-height": 900,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-small-width": 432,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-medium-width": 576,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-large-width": 864,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-large-width": 400,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        }
      },
      "layout": "feature_v9_bloom_custom_1",
      "section_background": "default",
      "section_margin": "default",
      "data_source": "default",
      "background_image": false
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "color-inherit",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_bloom_1",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "color-inherit",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": "https://www.youtube.com/embed/JdBYVNuky1M"
      },
      "layout": "feature_bloom_1_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "color-inherit",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": "https://www.youtube.com/embed/JdBYVNuky1M"
      },
      "layout": "feature",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "video_embed",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": "https://www.youtube.com/embed/JdBYVNuky1M"
      },
      "layout": "feature",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "This feature will preserver the aspect ratio of a supplied image. For ADA compliance images used in this layout must have an \"alt\" attribute",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 7,
          "id": 7,
          "title": "img-square-placeholder",
          "filename": "img-square-placeholder.png",
          "filesize": 94228,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=7",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-square-placeholder",
          "status": "inherit",
          "uploaded_to": 18,
          "date": "2021-04-21 21:52:25",
          "modified": "2021-04-21 22:21:29",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 900,
          "height": 900,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-300x300.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder-768x768.png",
            "medium_large-width": 768,
            "medium_large-height": 768,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "large-width": 900,
            "large-height": 900,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "1536x1536-width": 900,
            "1536x1536-height": 900,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "2048x2048-width": 900,
            "2048x2048-height": 900,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-small-width": 432,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-medium-width": 576,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "full-page-image-large-width": 864,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "sub-page-large-width": 400,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-square-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        },
        "video_embed": "https://www.youtube.com/embed/JdBYVNuky1M"
      },
      "layout": "feature_bloom_1",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_bloom_1_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "video_embed",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": "https://www.youtube.com/embed/JdBYVNuky1M"
      },
      "layout": "feature_bloom_1",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": false,
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "boxed_feature",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "boxed_feature_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "The feature v2 works best with longer form content",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v2",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "The feature v2 works best with longer form content",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v2_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v4",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v4_invert",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 6,
          "id": 6,
          "title": "img-rectangle-placeholder",
          "filename": "img-rectangle-placeholder.png",
          "filesize": 76949,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=6",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-rectangle-placeholder",
          "status": "inherit",
          "uploaded_to": 14,
          "date": "2021-04-21 21:52:24",
          "modified": "2021-04-21 22:15:05",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 890,
          "height": 556,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "large-width": 890,
            "large-height": 556,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "1536x1536-width": 890,
            "1536x1536-height": 556,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "2048x2048-width": 890,
            "2048x2048-height": 556,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-medium-width": 890,
            "full-page-image-medium-height": 556,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "full-page-image-large-width": 890,
            "full-page-image-large-height": 556,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-rectangle-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v11",
      "section_margin": "default",
      "section_background": "bg-1",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v11_bottom_center",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v11_right",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "feature",
      "default_data": {
        "pre_title": "",
        "title": "",
        "title_display_style": "default",
        "content": "",
        "link_1": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default",
        "media_type": "image",
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "video_embed": ""
      },
      "layout": "feature_v11_top_center",
      "section_margin": "default",
      "section_background": "default",
      "data_source": "default",
      "background_image": false,
      "divider_image": {
        "ID": 3439,
        "id": 3439,
        "title": "Mask-1",
        "filename": "Mask-1.svg",
        "filesize": 1715,
        "url": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
        "link": "https://base.bloomcudev.com/block-library/feature/mask-1-2/",
        "alt": "",
        "author": "1",
        "description": "",
        "caption": "",
        "name": "mask-1-2",
        "status": "inherit",
        "uploaded_to": 13,
        "date": "2022-11-10 06:44:26",
        "modified": "2022-11-10 06:44:26",
        "menu_order": 0,
        "mime_type": "image/svg+xml",
        "type": "image",
        "subtype": "svg+xml",
        "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
        "width": 1600,
        "height": 226,
        "sizes": {
          "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "thumbnail-width": 150,
          "thumbnail-height": 21,
          "medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium-width": 300,
          "medium-height": 42,
          "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "medium_large-width": 768,
          "medium_large-height": 108,
          "large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "large-width": 1024,
          "large-height": 145,
          "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "1536x1536-width": 1536,
          "1536x1536-height": 217,
          "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "2048x2048-width": 1600,
          "2048x2048-height": 226,
          "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-small-width": 960,
          "full-page-image-small-height": 136,
          "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-medium-width": 1280,
          "full-page-image-medium-height": 181,
          "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "full-page-image-large-width": 1600,
          "full-page-image-large-height": 226,
          "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-small-width": 960,
          "sub-page-small-height": 136,
          "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-medium-width": 1280,
          "sub-page-medium-height": 181,
          "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "sub-page-large-width": 1600,
          "sub-page-large-height": 226,
          "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-small-width": 64,
          "icon-small-height": 9,
          "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2022/11/Mask-1.svg",
          "icon-md-width": 128,
          "icon-md-height": 18
        }
      },
      "section_classes": ""
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 8,
          "id": 8,
          "title": "img-thumbnail-placeholder",
          "filename": "img-thumbnail-placeholder.png",
          "filesize": 19936,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=8",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-thumbnail-placeholder",
          "status": "inherit",
          "uploaded_to": 22,
          "date": "2021-04-21 21:52:26",
          "modified": "2021-04-21 22:23:21",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 300,
          "height": 300,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium_large-width": 300,
            "medium_large-height": 300,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "large-width": 300,
            "large-height": 300,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "1536x1536-width": 300,
            "1536x1536-height": 300,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "2048x2048-width": 300,
            "2048x2048-height": 300,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-small-width": 300,
            "full-page-image-small-height": 300,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-medium-width": 300,
            "full-page-image-medium-height": 300,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-large-width": 300,
            "full-page-image-large-height": 300,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-large-width": 300,
            "sub-page-large-height": 300,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "primary",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "secondary"
      },
      "layout": "coming-soon",
      "section_background": "bg-2",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 8,
          "id": 8,
          "title": "img-thumbnail-placeholder",
          "filename": "img-thumbnail-placeholder.png",
          "filesize": 19936,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=8",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-thumbnail-placeholder",
          "status": "inherit",
          "uploaded_to": 22,
          "date": "2021-04-21 21:52:26",
          "modified": "2021-04-21 22:23:21",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 300,
          "height": 300,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium_large-width": 300,
            "medium_large-height": 300,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "large-width": 300,
            "large-height": 300,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "1536x1536-width": 300,
            "1536x1536-height": 300,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "2048x2048-width": 300,
            "2048x2048-height": 300,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-small-width": 300,
            "full-page-image-small-height": 300,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-medium-width": 300,
            "full-page-image-medium-height": 300,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-large-width": 300,
            "full-page-image-large-height": 300,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-large-width": 300,
            "sub-page-large-height": 300,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "color-inherit"
      },
      "layout": "coming-soon-reverse",
      "section_background": "bg-2",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 8,
          "id": 8,
          "title": "img-thumbnail-placeholder",
          "filename": "img-thumbnail-placeholder.png",
          "filesize": 19936,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=8",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-thumbnail-placeholder",
          "status": "inherit",
          "uploaded_to": 22,
          "date": "2021-04-21 21:52:26",
          "modified": "2021-04-21 22:23:21",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 300,
          "height": 300,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium_large-width": 300,
            "medium_large-height": 300,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "large-width": 300,
            "large-height": 300,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "1536x1536-width": 300,
            "1536x1536-height": 300,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "2048x2048-width": 300,
            "2048x2048-height": 300,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-small-width": 300,
            "full-page-image-small-height": 300,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-medium-width": 300,
            "full-page-image-medium-height": 300,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-large-width": 300,
            "full-page-image-large-height": 300,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-large-width": 300,
            "sub-page-large-height": 300,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "color-inherit"
      },
      "layout": "image-fixed-size",
      "section_background": "bg-2",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 8,
          "id": 8,
          "title": "img-thumbnail-placeholder",
          "filename": "img-thumbnail-placeholder.png",
          "filesize": 19936,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=8",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-thumbnail-placeholder",
          "status": "inherit",
          "uploaded_to": 22,
          "date": "2021-04-21 21:52:26",
          "modified": "2021-04-21 22:23:21",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 300,
          "height": 300,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium-width": 300,
            "medium-height": 300,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "medium_large-width": 300,
            "medium_large-height": 300,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "large-width": 300,
            "large-height": 300,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "1536x1536-width": 300,
            "1536x1536-height": 300,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "2048x2048-width": 300,
            "2048x2048-height": 300,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-small-width": 300,
            "full-page-image-small-height": 300,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-medium-width": 300,
            "full-page-image-medium-height": 300,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "full-page-image-large-width": 300,
            "full-page-image-large-height": 300,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-small-width": 200,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-medium-width": 267,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "sub-page-large-width": 300,
            "sub-page-large-height": 300,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 64,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-thumbnail-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 128
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "image-fixed-size-reverse",
      "section_background": "default",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "image-fixed-size-lg",
      "section_background": "default",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "image-fixed-size-lg-reverse",
      "section_background": "default",
      "section_margin": "no_margin",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "Sub [table-cell table=\"example-table\" row=\"1\" col=\"A\"] . Ullam vero aliquam, natus quae architecto hic quod!",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "left-content",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": false,
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "default",
      "section_background": "bg-2",
      "section_margin": "default",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    },
    {
      "acf_fc_layout": "hero",
      "hero_fields_default": {
        "image": {
          "ID": 5,
          "id": 5,
          "title": "img-hero-placeholder",
          "filename": "img-hero-placeholder.png",
          "filesize": 161445,
          "url": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
          "link": "https://base.bloomcudev.com/?attachment_id=5",
          "alt": "",
          "author": "1",
          "description": "",
          "caption": "",
          "name": "img-hero-placeholder",
          "status": "inherit",
          "uploaded_to": 11,
          "date": "2021-04-21 21:52:22",
          "modified": "2022-01-21 19:04:37",
          "menu_order": 0,
          "mime_type": "image/png",
          "type": "image",
          "subtype": "png",
          "icon": "https://base.bloomcudev.com/wp-includes/images/media/default.png",
          "width": 1516,
          "height": 947,
          "sizes": {
            "thumbnail": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-150x150.png",
            "thumbnail-width": 150,
            "thumbnail-height": 150,
            "medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-300x187.png",
            "medium-width": 300,
            "medium-height": 187,
            "medium_large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-768x480.png",
            "medium_large-width": 768,
            "medium_large-height": 480,
            "large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder-1024x640.png",
            "large-width": 1024,
            "large-height": 640,
            "1536x1536": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "1536x1536-width": 1516,
            "1536x1536-height": 947,
            "2048x2048": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "2048x2048-width": 1516,
            "2048x2048-height": 947,
            "full-page-image-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-small-width": 692,
            "full-page-image-small-height": 432,
            "full-page-image-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-medium-width": 922,
            "full-page-image-medium-height": 576,
            "full-page-image-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "full-page-image-large-width": 1383,
            "full-page-image-large-height": 864,
            "sub-page-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-small-width": 320,
            "sub-page-small-height": 200,
            "sub-page-medium": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-medium-width": 427,
            "sub-page-medium-height": 267,
            "sub-page-large": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "sub-page-large-width": 640,
            "sub-page-large-height": 400,
            "icon-small": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-small-width": 64,
            "icon-small-height": 40,
            "icon-md": "https://base.bloomcudev.com/wp-content/uploads/2021/04/img-hero-placeholder.png",
            "icon-md-width": 128,
            "icon-md-height": 80
          }
        },
        "pre_title": "",
        "title": "",
        "sub_title": "",
        "": null,
        "link": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_1_style": "default",
        "link_2": {
          "title": "",
          "url": "#",
          "target": ""
        },
        "link_2_style": "default"
      },
      "layout": "boxed",
      "section_background": "default",
      "section_margin": "default",
      "background_image": false,
      "divider_image": false,
      "data_source": "default"
    }
]

export default wordpressBlockSchemas;